<template>
  <common-form
    name="employer"
    @submit="submitData"
  >
    <pp-frame>
      <template #message>
        <h2 class="blue-grey--text">
          <p>{{ $t('Employer Information') }}</p>
        </h2>

        <div class="secondary--text">
          {{ $t('Add your employer information here.') }}
          <br>
          {{ translate(`
          This information is optional but may help us
          more quickly verify your insurance coverage/benefits,
          especially if you have an employee-sponsored plan.
          `) }}
        </div>
      </template>

      <v-row class="flex-grow-0">
        <v-col cols="12">
          <v-text-field
            v-model="info.employer"
            :label="`1. ${employerLabel}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="info.empAdd1"
            :label="`2. ${empAdd1Label}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="info.empAdd2"
            :label="`3. ${empAdd2Label}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.empCity"
            :label="`4. ${empCityLabel}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="info.empState"
            :items="states"
            :label="`5. ${empStateLabel}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.empZip"
            v-mask="'#####-####'"
            :label="`6. ${empZipLabel}:`"
            autocomplete="off"
            :rules="[!info.empZip ||
              $validators.isZipCode()
            ]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="info.empPhone"
            v-mask="'(###) ###-####'"
            :label="`7. ${empPhoneLabel}:`"
            autocomplete="off"
            :rules="[!info.empPhone ||
              $validators.isValidPhone()]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="info.empFax"
            v-mask="'(###) ###-####'"
            :label="`8. ${empFaxLabel}:`"
            autocomplete="off"
            :rules="[!info.empFax ||
              $validators.isValidFax()]"
            dense
          />
        </v-col>
      </v-row>

      <template #actions>
        <v-row class="ma-0">
          <v-btn
            :to="info.hasPMIns
              ? '/register/second-ins' : '/register/insurance'"
          >
            <v-icon>mdi-chevron-double-left</v-icon>
            {{ $t('Back') }}
          </v-btn>

          <v-spacer />

          <v-btn
            color="primary"
            type="submit"
            :loading="updatingProfile"
          >
            {{ $t('Proceed') }}
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import { utils, validators, events } from 'ui-common'
import { useProfile } from '@/use/profile'
import { states, PageEmployer } from '@/constants'
import { useQuestionnaire } from '@/use/questionnaire'

const useEmployer = () => {
  const info = ref({
    employer: '',
    empAdd1: '',
    empAdd2: '',
    empCity: '',
    empState: '',
    empZip: '',
    empPhone: '',
    empFax: ''
  })

  const {
    secondaryProfile,

    updatingProfile,
    updateSecondaryProfile
  } = useProfile()

  const {
    getCompletedSections
  } = useQuestionnaire()

  const feedData = () => {
    const {
      employer = '',
      empAdd1 = '',
      empAdd2 = '',
      empCity = '',
      empState = '',
      empZip = '',
      empPhone = '',
      empFax = '',
      hasPMIns
    } = secondaryProfile.value

    info.value = {
      employer,
      empAdd1,
      empAdd2,
      empCity,
      empState,
      empZip,
      empPhone: validators.formatPhoneNumber(empPhone),
      empFax: validators.formatPhoneNumber(empFax),
      hasPMIns: hasPMIns === 'Yes'
    }
  }

  const submitData = async () => {
    try {
      const payload = { ...info.value }
      delete payload.hasPMIns

      await updateSecondaryProfile({
        info: {
          ...payload,
          empPhone: validators.formatPhoneNumber(payload.empPhone, false),
          empFax: validators.formatPhoneNumber(payload.empFax, false),
          lastRegSect: PageEmployer
        }
      })
      await getCompletedSections()
      utils.navigate('/register')
    } catch (err) {}
  }

  return {
    info,
    feedData,
    submitData,
    updatingProfile
  }
}

export default {
  setup: (props, context) => {
    const { root } = context

    const {
      info,
      feedData,
      submitData,
      updatingProfile
    } = useEmployer()

    onMounted(() => {
      feedData()
      root.$nextTick(() => {
        events.emit('form.reset.employer')
      })
    })

    return {
      info,
      states,
      submitData,
      updatingProfile,

      employerLabel: computed(() =>
        root.$i18n.t('Employer')
      ),
      empAdd1Label: computed(() =>
        root.$i18n.t('Address 1')
      ),
      empAdd2Label: computed(() =>
        root.$i18n.t('Address 2')
      ),
      empCityLabel: computed(() =>
        root.$i18n.t('City')
      ),
      empStateLabel: computed(() =>
        root.$i18n.t('State')
      ),
      empZipLabel: computed(() =>
        root.$i18n.t('Zip Code')
      ),
      empPhoneLabel: computed(() =>
        root.$i18n.t('Phone')
      ),
      empFaxLabel: computed(() =>
        root.$i18n.t('Fax')
      )
    }
  }
}
</script>
